// App.js
import React from 'react';
import GlobalStyle from './GlobalStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminPanel from './AdminPanel';
import Survey from './Survey';
import Verificar from './verificar';
import { ToastContainer } from 'react-toastify';




const App = () => {
  return (
    <Router>
      <div>
        <GlobalStyle />
        <ToastContainer />
        <Routes>
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/verificar" element={<Verificar />} />
          <Route path="/" element={<Survey />} />
        </Routes>
      </div>
    </Router>
  );
};


export default App;
