// AdminPanel.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';
import 'firebase/compat/functions';
import styled from 'styled-components';
import NavButton from './navButton';
import LogoutButton from './logoutButton';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar, FaRegStar, FaPlus } from 'react-icons/fa';


const Pagebody = styled.div`
    background-image: url('https://firebasestorage.googleapis.com/v0/b/encuesta-smiths.appspot.com/o/img%2Fbg%2FsmithsBg4.jpg?alt=media&token=4b7940b4-9502-49d4-aaf7-0ad36b124dda');
    background-size: cover;
    background-position: top;
`


const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 70%;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  min-height: 100vh;
  width: 70%;
`;

const BlurContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5); // Adjust the alpha value for transparency
  padding: 20px;
  border-radius: 10px; // Add border radius for rounded corners
  backdrop-filter: blur(30px); // Optional: Apply a blur effect to the background
  -webkit-backdrop-filter: blur(30px);
  margin-bottom: 16px;
  width: 100%;
`

// Wrap the survey count
const SurveyContainer = styled.div`
  display: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px; // Adjust margin as needed
`;

// Add the following styles to the survey count
const SurveysCount = styled.h3`
  margin: 0;
  font-size: 18px;
`;

const AdminList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`;

const AdminListItem = styled.li`
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    background-color: rgba(128, 128, 128, 0.15)
  }

  ${(props) => props.isSelected && `background-color: rgba(128, 128, 128, 0.15); color: white;`}

  border: none;
  transition: background-color 0.3s, border 0.3s; // Add border transition

  cursor: pointer;
`;

const AdminInput = styled.input`
  font-family: 'Oswald', sans-serif;
  width: 60%;
  padding: 8px;
  margin-top: 8px;
  margin-right: 15px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: none;
  border-radius: 4px; /* Add rounded corners */
  font-size: 14px; /* Set the font size */
  text-align: center; /* Center the text */
  color: black;
  background-color: rgba(128, 128, 128, 0.1); // Adjust the alpha value for transparency
  backdrop-filter: blur(20px); // Optional: Apply a blur effect to the background
  outline: 1px solid #ccc;

  resize: none;

  &:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const AdminSelect = styled.select`
  font-family: 'Oswald', sans-serif;
  width: 20%;
  padding: 8px;
  margin-top: 8px;
  /* Apply styles for the select box */
  background-color: rgba(128, 128, 128, 0.1); 
  color: gray;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  outline: 1px solid #ccc;
  cursor: pointer;
  /* Add focus styles */
  &:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const AdminButton = styled.button`
  background-color: #030759;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 8px;
  margin-top: 8px;
  &:hover {
    background-color: #818FB4;
  }
`;

const DeleteButton = styled.button`
  background-color: #e74c3c;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 8px;
  margin-left: 8px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const GetEmailsButton = styled.button`
  background-color: #030759;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 8px;
  margin-left: 8px;

  &:hover {
    background-color: #2a65c7;
  }
`;

const EditInput = styled.input`
  font-family: 'Oswald', sans-serif;
  width: 220px;
  padding: 8px;
  margin-top: 8px;
  margin-right: 15px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: none;
  border-radius: 4px; /* Add rounded corners */
  font-size: 14px; /* Set the font size */
  text-align: center; /* Center the text */
  color: black;
  background-color: rgba(128, 128, 128, 0.1); // Adjust the alpha value for transparency
  backdrop-filter: blur(20px); // Optional: Apply a blur effect to the background
  outline: 1px solid #ccc;

  resize: none;

  &:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const VerificarButton = styled(NavButton)`
  background-color: #030759;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 8px;
  text-decoration: none;
  max-width: 200px;
  width: 100%;
  &:hover {
    background-color: #2a65c7;
  }
`;



const ScrollableContainer = styled.div`
  max-height: 400px; // Set a maximum height for the container
  overflow-y: auto; // Enable vertical scrolling
  overflow-x: hidden;

  scrollbar-width: thin; // Set the width of the scrollbar (for Firefox)
  scrollbar-color: #fff; // Set the color of the scrollbar (for Firefox)
  
  &::-webkit-scrollbar {
    width: 5px; // Set the width of the scrollbar (for Chrome, Safari, and Opera)
    scrollbar-color: #fff
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.01); // Set the color of the scrollbar thumb (for Chrome, Safari, and Opera)
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.01); // Set the color of the scrollbar track (for Chrome, Safari, and Opera)
  }
`;


const ResponseContainer = styled.div`
  max-height: 200px; // Set a maximum height for the container
  overflow-y: auto; // Enable vertical scrolling
  overflow-x: hidden;
  width: 100%;


  scrollbar-width: thin; // Set the width of the scrollbar (for Firefox)
  scrollbar-color: #fff; // Set the color of the scrollbar (for Firefox)
  
  &::-webkit-scrollbar {
    width: 5px; // Set the width of the scrollbar (for Chrome, Safari, and Opera)
    scrollbar-color: #fff
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.01); // Set the color of the scrollbar thumb (for Chrome, Safari, and Opera)
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.01); // Set the color of the scrollbar track (for Chrome, Safari, and Opera)
  }
`;
const ResponseTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 20px;
  color: black;
`;

const StyledResponseList = styled.ul`
  list-style: none;
  padding: 0;
`;


const StyledResponseItem = styled.li`
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow-wrap: break-word;
  background-color: rgba(255, 255, 255, 0.3); 

  &:before {
    content: "${({ responseDate }) => {
        return responseDate.slice(0, 5);

    }}"; 
    position: absolute;
    top: 1px;
    right: 5px;
    font-size: 12px;
    color: #777;
  }

  .scale-response-stars {
    display: flex;
    align-items: center;
    justify-content: center; // Center stars
    margin-top: 8px;
  }

  .scale-response-star {
    margin-right: 4px;
    font-size: 20px; // Increase star size
    color: #FFD700;
  }
`;

const GoogleSignInButton = styled.button`
  background-color: #030759;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #2a65c7;
  }
`;

const LogoImage = styled.img`
  user-select: none;
  width: 100%; /* Adjust the width as needed */
  max-width: 450px; /* Set a max-width to prevent stretching on larger screens */
  height: auto;
  display: block;
  margin: 0 auto;
`;

const RedText = styled.div`
    color: orange;
`



const AdminPanel = () => {
    const [user, setUser] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [addingQuestion, setAddingQuestion] = useState(false);
    const [editedText, setEditedText] = useState('');
    const [editedType, setEditedType] = useState('');
    const [newQuestionText, setNewQuestionText] = useState('');
    const [newQuestionType, setNewQuestionType] = useState('');
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [responses, setResponses] = useState([]);
    const [surveysCount, setSurveysCount] = useState(0); // Add state to store surveys count
    const [userEmails, setUserEmails] = useState([]);



    const navigate = useNavigate();



    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
            setUser(authUser);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (user) {
            fetchQuestions();
            getSurveyCount();
        }
    }, [user]);

    const getSurveyCount = async () => {
        try {
            const surveysCollection = await firebase.firestore().collection('surveys').get();
            const count = surveysCollection.size;
            setSurveysCount(count);
            // You can use the count variable as needed for your application
        } catch (error) {
            console.error('Error counting documents in the "surveys" collection:', error);
        }
    };

    const fetchUserEmails = async () => {
        // Call the fetchEmail function from index.js
        try {
            const getUserEmails = firebase.functions().httpsCallable('getUserEmails');
            const result = await getUserEmails({
                userEmail: user.email,
            });
            setUserEmails(result.data);
            console.log(userEmails)
            toast.success('Correos enviados a ' + user.email, {
                position: toast.POSITION.TOP_CENTER,
            });
        } catch (error) {
            console.error('Error fetching emails:', error);
            toast.error('Error!', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const fetchQuestions = async () => {
        try {
            const getQuestions = firebase.functions().httpsCallable('getQuestions');
            const result = await getQuestions();
            setQuestions(result.data.sort((a, b) => a.order - b.order));
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const fetchResponses = async (questionId) => {
        try {
            console.log('Fetching responses for questionId:', questionId);

            const responsesSnapshot = await firebase.firestore().collection('surveys')
                .get();

            const responseList = responsesSnapshot.docs
                .map(doc => {
                    const data = doc.data();
                    const timestamp = data.timestamp ? new Date(data.timestamp.seconds * 1000) : new Date(0);
                    const formattedDate = timestamp.toLocaleDateString('en-GB');
                    return {
                        id: doc.id,
                        data: data.data.find(item => item.questionId === questionId),
                        timestamp: formattedDate, // Format date to 'dd/mm'
                        rawTimestamp: timestamp // Keep the raw timestamp for sorting
                    };
                })
                .filter(response => response.data !== undefined) // Filter out responses without the selected question
                .sort((a, b) => b.rawTimestamp - a.rawTimestamp); // Sort by raw timestamp

            setResponses(responseList);
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    };






    const saveQuestion = async (localEditedText) => {
        try {
            if (!localEditedText.trim()) {
                toast.error('Introduzca una pregunta antes de guardar.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }

            const updateQuestion = firebase.functions().httpsCallable('updateQuestion');
            await updateQuestion({ id: editingQuestion, text: localEditedText, type: editedType });
            setEditingQuestion(null);
            setShowDeleteButton(false);
            fetchQuestions();
        } catch (error) {
            console.error('Error editing question:', error);
        }
    };


    const addQuestion = async () => {
        try {
            if (!newQuestionText.trim()) {
                toast.error('Introduzca una pregunta antes de añadir.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }

            const createQuestion = firebase.functions().httpsCallable('createQuestion');
            const type = newQuestionType || 'text'; // Set type to 'text' if it is empty
            await createQuestion({ text: newQuestionText, type });
            setNewQuestionText('');
            setNewQuestionType('');
            fetchQuestions();
        } catch (error) {
            console.error('Error adding question:', error);
        }
    };

    const handleLogout = () => {
        firebase.auth().signOut().then(() => {
            navigate('/'); // Redirect to home page after logout
        });
    };

    const deleteQuestion = async (questionId) => {
        try {
            const deleteQuestionFn = firebase.functions().httpsCallable('deleteQuestion');
            await deleteQuestionFn({ id: questionId });
            setShowDeleteButton(false);
            fetchQuestions(); // Refresh the question list after deletion
        } catch (error) {
            console.error('Error deleting question:', error);
        }
    };

    const toggleEditQuestion = async (question) => {
        if (editingQuestion === question.id) {
            // If already editing, cancel
            setEditingQuestion(null);
            setShowDeleteButton(false);
            setSelectedQuestion(null);
            setResponses([]);
            setAddingQuestion(false); // Deselect the "New Question" part
        } else {
            // Start editing
            setEditingQuestion(question.id);
            setEditedText(question.text);
            setEditedType(question.type);
            setShowDeleteButton(true);
            setSelectedQuestion(question);

            // Fetch responses for the selected question
            await fetchResponses(question.id);
            setAddingQuestion(false); // Deselect the "New Question" part
        }
    };

    const toggleAddQuestion = () => {
        setAddingQuestion(!addingQuestion);
        setNewQuestionText('');
        setNewQuestionType('');
        setEditingQuestion(null); // Deselect an actual question
        setSelectedQuestion(null);
        setResponses([]);
    };

    const handleInputChange = (e) => {
        e.stopPropagation();
    };

    const handleEditInputChange = (e) => {
        e.stopPropagation();
        setEditedText(e.target.value);
    };

    const handleDeleteAllSurveys = async () => {
        try {
            const deleteAllSurveysFn = firebase.functions().httpsCallable('deleteAllSurveys');
            const result = await deleteAllSurveysFn();
            console.log(result.data.message);
            // Optionally, you can refresh the surveys count or perform any other actions
            getSurveyCount();
        } catch (error) {
            console.error('Error deleting all surveys:', error);
        }
    };

    const handleDeleteAllCoupons = async () => {
        try {
            const deleteAllCouponsFn = firebase.functions().httpsCallable('deleteAllCoupons');
            const result = await deleteAllCouponsFn();
            console.log(result.data.message);
            // Optionally, you can refresh the coupons count or perform any other actions
        } catch (error) {
            console.error('Error deleting all coupons:', error);
        }
    };

    const handleDeleteData = async () => {

        try {
            const confirmDelete = window.confirm("Are you sure you want to delete all coupons?");
            if (!confirmDelete) {
                return; // If user cancels the deletion, do nothing
            }
            handleDeleteAllSurveys();
            handleDeleteAllCoupons();
        } catch (error) {
            console.error('Error deleting all coupons:', error);
            // Display error toast
            toast.error('Error deleting coupons. Please try again.', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            await firebase.auth().signInWithPopup(provider);
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    const getPreviewImage = (monitorProps) => {
        const item = monitorProps.item;
        const type = item.type;

        if (type === 'QUESTION') {
            return <FaPlus />;
        }

        return null;
    };

    const QuestionItem = ({ question, index, moveQuestion }) => {
        const [localEditedText, setLocalEditedText] = useState(question.text); // Local state for edited text

        useEffect(() => {
            setLocalEditedText(question.text); // Update local state when question prop changes
        }, [question]);

        const [{ isDragging }, drag, preview] = useDrag({
            type: 'QUESTION',
            item: { id: question.id, index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const [, drop] = useDrop({
            accept: 'QUESTION',
            hover: (draggedItem) => {
                if (draggedItem.index !== index) {
                    moveQuestion(draggedItem.index, index);
                    draggedItem.index = index;
                }
            },
        });

        return (
            <AdminListItem
                // Conditionally apply drag and drop based on whether the question is being edited
                ref={(node) => (question.id === editingQuestion ? null : drag(drop(node)))}
                isSelected={editingQuestion === question.id}
                style={{ opacity: isDragging ? 0 : 1 }}
                onClick={() => toggleEditQuestion(question)}
            >
                <div>
                    {editingQuestion === question.id ? (
                        <div>
                            <EditInput
                                type="text"
                                placeholder="Pregunta"
                                value={localEditedText}
                                onChange={(e) => setLocalEditedText(e.target.value)}
                                onClick={handleInputChange}
                            />
                            <div>
                                <AdminButton onClick={() => saveQuestion(localEditedText)}>Guardar</AdminButton>
                                {showDeleteButton && (
                                    <DeleteButton onClick={() => deleteQuestion(question.id)}>Borrar</DeleteButton>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {question.type === '' ? 'Text Response' : (
                                <>
                                    {question.type === 'scale' && <FaRegStar />}
                                    {question.text}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </AdminListItem>
        );
    };

    const moveQuestion = async (fromIndex, toIndex) => {
        const updatedQuestions = [...questions];
        const [movedQuestion] = updatedQuestions.splice(fromIndex, 1);
        updatedQuestions.splice(toIndex, 0, movedQuestion);

        setQuestions(updatedQuestions);

        // Update the order in Firestore
        await updateQuestionOrderInFirestore(updatedQuestions);
    };

    const updateQuestionOrderInFirestore = async (updatedQuestions) => {
        try {
            const updateOrder = firebase.functions().httpsCallable('updateQuestionOrder');
            await updateOrder({ updatedQuestions: updatedQuestions.map((q, index) => ({ id: q.id, order: index })) });
        } catch (error) {
            console.error('Error updating question order:', error);
        }
    };

    return (

        <Pagebody>

            <DndProvider backend={HTML5Backend}>
                <LogoImage src={'https://firebasestorage.googleapis.com/v0/b/encuesta-smiths.appspot.com/o/img%2Flogo%2FsmithsLogo2.png?alt=media&token=7d1d1e5f-6f42-40fb-9c87-998620efb9cc'} alt="SMITHS" />
                <AdminContainer>


                    {(user && user.uid === "rgVW21y2HSQIzWcRFqFIIPjdYBg1") || (user && user.uid === "tZVG0YyDLWcKAxg6gdyRXAGNYmW2") || (user && user.uid === "INeyu2fmmihw1Vg5ohDfEPR438e2") ? ( // admin user ID
                        <>
                            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
                            <BlurContainer>
                                <h1>Preguntas</h1>
                                {addingQuestion ? (
                                    <AdminListItem isSelected={true} onClick={toggleAddQuestion}>
                                        <div>
                                            <AdminInput
                                                type="text"
                                                placeholder="Nueva Pregunta"
                                                value={newQuestionText}
                                                onChange={(e) => setNewQuestionText(e.target.value)}
                                                onClick={handleInputChange}
                                            />
                                            <AdminSelect
                                                value={newQuestionType}
                                                onChange={(e) => setNewQuestionType(e.target.value)}
                                                onClick={handleInputChange}
                                            >
                                                <option value="text">Texto</option>
                                                <option value="scale">1-5</option>
                                            </AdminSelect>
                                            <AdminButton onClick={addQuestion}>Agregar Pregunta</AdminButton>
                                            <AdminButton onClick={toggleAddQuestion}>Cancelar</AdminButton>
                                        </div>
                                    </AdminListItem>
                                ) : (
                                    <AdminListItem onClick={toggleAddQuestion}>
                                        <div>
                                            <FaPlus />
                                        </div>
                                    </AdminListItem>
                                )}
                                <ScrollableContainer>
                                    <AdminList>
                                        {questions.map((question, index) => (
                                            <QuestionItem
                                                key={question.id}
                                                question={question}
                                                index={index}
                                                moveQuestion={moveQuestion}
                                            />
                                        ))}
                                    </AdminList>
                                </ScrollableContainer>
                            </BlurContainer>


                            {selectedQuestion ? (
                                <BlurContainer>
                                    <h1>Respuestas</h1>
                                    <ResponseTitle>{selectedQuestion.text}</ResponseTitle>
                                    <ResponseContainer>
                                        <StyledResponseList>
                                            {responses.map((response) => (
                                                <StyledResponseItem
                                                    key={response.id}
                                                    responseDate={response.timestamp}
                                                >
                                                    {selectedQuestion.type === 'scale' && (
                                                        <div className="scale-response-stars">
                                                            {[1, 2, 3, 4, 5].map((rating) => (
                                                                // Use conditional rendering to determine whether to render a filled or empty star
                                                                response.data.response >= rating ? (
                                                                    <FaStar key={rating} className="scale-response-star" />
                                                                ) : (
                                                                    <FaRegStar key={rating} className="scale-response-star" />
                                                                )
                                                            ))}
                                                        </div>
                                                    )}
                                                    {response.data.response}
                                                </StyledResponseItem>
                                            ))}
                                        </StyledResponseList>
                                    </ResponseContainer>
                                </BlurContainer>
                            ) : (
                                <BlurContainer>
                                    <SurveyContainer>
                                        <SurveysCount><h3><RedText>{`${surveysCount}`}</RedText>Encuestas</h3></SurveysCount>
                                        <DeleteButton onClick={handleDeleteData}>Reset</DeleteButton>
                                        <GetEmailsButton onClick={fetchUserEmails}>Correos</GetEmailsButton>

                                    </SurveyContainer>
                                </BlurContainer>
                            )}

                            <VerificarButton to="/verificar">
                                Verificación
                            </VerificarButton>
                        </>
                    ) : (
                        <BlurContainer>
                            <h3>Inicie sesión como administrador para acceder al panel de administración.</h3>
                            <GoogleSignInButton onClick={handleGoogleSignIn}>
                                Inicia sesión con Google
                            </GoogleSignInButton>
                        </BlurContainer>
                    )}
                </AdminContainer>

            </DndProvider>
        </Pagebody>
    );
};

export default AdminPanel;
