// LogoutButton.js
import styled from 'styled-components';

const LogoutButton = styled.button`
  background-color: #030759;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    background-color: #818fb4;
  }
`;

export default LogoutButton;
