// firebaseConfig.js
const firebaseConfig = {
    apiKey: "AIzaSyBHXTg5KZx5opES08H4c18YPMUnRCIVLyE",
    authDomain: "www.smithstepregunta.com",
    projectId: "encuesta-smiths",
    storageBucket: "encuesta-smiths.appspot.com",
    messagingSenderId: "116935129694",
    appId: "1:116935129694:web:0f453bffcf1bdcb06b92cf",
    measurementId: "G-KLXEK234K7"
};

export default firebaseConfig;
